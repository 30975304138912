var InsightsFilterComponent = {

    constants: {
        $lvl1List: $('.BrowseByIndustry-level1List'),
        $lvl1Btn: $(".BrowseByIndustry-level1Btn"),
        $navLeft: $('.BrowseByIndustry-navigate--left'),
        $navRight: $('.BrowseByIndustry-navigate--right'),
        isActiveClass: "is-active",
        isHiddenClass: "is-hidden",
        isVisibleClass: "is-visible"
    },

    showHide: function () {
        $(".InsightsFilterComponent-showAll").each(function () {
            var $this = $(this),
                more = $this.data("all"),
                less = $this.data("less");

            $this.on("click", function () {
                $this.toggleClass("is-expanded");
                $this.text(more === $this.text() ? less : more);
                $(".InsightsFilterComponent-filterMoreWrapper").toggleClass("d-inline d-none");
            });
        });
    },

    plusMinusTagBtn: function () {
        $("button.InsightsListComponent-tagBtn").each(function () {
            var $this = $(this);

            $this.click(function () {
                $this.toggleClass("minus");
                if ($this.hasClass("minus")) {
                    $this.html("-");
                    $this.prevAll("#startTagHidden").toggleClass("d-none");
                } else {
                    $this.html("+");
                    $this.prevAll("#startTagHidden").toggleClass("d-none");
                }
            });
        });
    },

    showMoreArticlesBtn: function () {
        $("button.showMoreArticlesBtn").each(function () {
            var $this = $(this);

            $this.click(function () {

                var countInfo = $this.data("count");
                var numberOfArticlesToShow = $this.data("numberofarticlestoshow");
                var showAllBtnText = $this.data("showallbtntext");
                var showLessBtnText = $this.data("showlessbtntext");
                var btnConjunctionText = $this.data("btnconjunctiontext");

                $this.toggleClass("less");
                var spanElement = "";

                spanElement = "<span class='InsightsListComponent-showMoreHits'>(" + numberOfArticlesToShow + " " + btnConjunctionText + " " + countInfo + ")</span>";

                if ($this.hasClass("less")) {
                    $this.html(showLessBtnText);
                } else {
                    $this.html(showAllBtnText).append(spanElement);
                }

                $("div #smallHiddenCard").each(function () {
                    $(this).toggleClass("d-none");
                });

            });

            
        });
    },

    init: function () {
        InsightsFilterComponent.showHide();
        InsightsFilterComponent.plusMinusTagBtn();
        InsightsFilterComponent.showMoreArticlesBtn();
    }
};

$(function () {
    InsightsFilterComponent.init();
});
